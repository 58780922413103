<template>
  <div
    class="c-app flex-row align-items-center"
    v-bind:style="{ 'background-image': 'url(' + background + ')' }"
    style="background-size: cover"
  >
    <Loading :isActive="loading" />
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm v-on:keyup.enter="onSubmit">
                  <h2>Painel Administrativo</h2>
                  <small v-show="!!loginError" class="text-danger">
                    {{ loginError }}
                  </small>
                  <CInput
                    placeholder="CPF, Email ou Matrícula"
                    autocomplete="username email"
                    v-model="user"
                  />
                  <CInput
                    placeholder="Senha"
                    type="password"
                    autocomplete="curent-password"
                    v-model="pass"
                  />
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4 button-login"
                        @click="onSubmit"
                      >
                        LOGIN
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>

                <div class="dropdown-api-login mt-3 mx-auto">
                  <ApiUrlChange />
                </div>
              </CCardBody>
            </CCard>
            <CCard
              color="light"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <img v-bind:src="logo" alt="logo" class="" />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import config from "../../config";
import ApiUrlChange from "../../components/ApiUrlChange.vue";
import { getToken } from "@/helpers/apiRequest";
import { loadCargas } from "../../helpers/cargas";

export default {
  name: "login",
  components: {
    ApiUrlChange,
  },
  data() {
    return {
      logo: config.api + "/images/logo",
      background: config.api + "/images/background/ADMIN",
      user: "",
      pass: "",
      loginError: false,
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      getToken({ cpf: this.user, senha: this.pass })
        .then((json) => {
          localStorage.setItem("token", json.token);
          loadCargas(
            json.token,
            (carga) => {
              this.$router.push({ name: "home" });
              this.loading = false;
            },
            (err) => {
              this.loading = false;
              this.loginError = err.message;
            }
          );
        })
        .catch((error) => {
          this.loading = false;
          this.loginError = error.message;
        });
    },
  },
};
</script>

<style scoped>
.button-login {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
}
</style>
