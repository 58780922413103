<template>
  <div v-if="apis.length > 0">
    <CDropdown
      class="dropdown-api"
      placement="bottom-start"
      color="primary"
      variant="btn-group"
      :togglerText="'ALTERAR NEGÓCIO'"
    >
      <CDropdownMenu>
        <CDropdownItem
          @click="changeApi(api)"
          v-for="api in apis"
          :key="api.API_URL"
        >
          <img
            :src="`${api.API_URL}/images/favicon/icon-72x72`"
            alt="Icone sistema"
            class="api-icon"
          />
          <span class="api-name">
            {{ api.NAME }}
          </span>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  </div>
</template>

<script>
import config from "../config";
import _find from "lodash/find";

export default {
  name: "ApiUrlChange",
  data() {
    return {
      apis: {},
      active: null,
    };
  },
  methods: {
    changeApi(api) {
      localStorage.clear();

      localStorage.setItem("API_URL", api.API_URL);
      localStorage.setItem("CDN_URL", api.CDN_URL);
      localStorage.setItem("WS_HOST", api.WS_HOST);
      localStorage.setItem("WS_KEY", api.WS_KEY);
      localStorage.setItem("WS_PORT", api.WS_PORT);

      window.location.reload();
    },
  },
  beforeMount() {
    this.active = _find(config.apis, { API_URL: config.api });
    this.apis = config.apis;
  },
};
</script>

<style scoped>
.api-url {
  display: block;
}
.api-icon {
  max-width: 22px;
  max-height: 22px;
  margin-right: 10px;
  object-fit: contain;
  overflow: hidden;
}
.api-name {
  font-size: 13px;
}
</style>
